// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-photography-js": () => import("./../../../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-pages-writing-js": () => import("./../../../src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */),
  "component---src-pages-writing-remembering-flash-history-repeats-mdx": () => import("./../../../src/pages/writing/remembering-flash/history-repeats.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-history-repeats-mdx" */),
  "component---src-pages-writing-remembering-flash-intro-mdx": () => import("./../../../src/pages/writing/remembering-flash/intro.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-intro-mdx" */),
  "component---src-pages-writing-remembering-flash-js": () => import("./../../../src/pages/writing/remembering-flash.js" /* webpackChunkName: "component---src-pages-writing-remembering-flash-js" */),
  "component---src-pages-writing-remembering-flash-open-web-mdx": () => import("./../../../src/pages/writing/remembering-flash/open-web.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-open-web-mdx" */),
  "component---src-pages-writing-remembering-flash-preservation-mdx": () => import("./../../../src/pages/writing/remembering-flash/preservation.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-preservation-mdx" */),
  "component---src-pages-writing-remembering-flash-the-most-creative-period-of-the-web-mdx": () => import("./../../../src/pages/writing/remembering-flash/the-most-creative-period-of-the-web.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-the-most-creative-period-of-the-web-mdx" */),
  "component---src-pages-writing-remembering-flash-thoughts-end-mdx": () => import("./../../../src/pages/writing/remembering-flash/thoughts-end.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-thoughts-end-mdx" */),
  "component---src-pages-writing-remembering-flash-thoughts-middle-mdx": () => import("./../../../src/pages/writing/remembering-flash/thoughts-middle.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-thoughts-middle-mdx" */),
  "component---src-pages-writing-remembering-flash-thoughts-on-thoughts-on-flash-mdx": () => import("./../../../src/pages/writing/remembering-flash/thoughts-on-thoughts-on-flash.mdx" /* webpackChunkName: "component---src-pages-writing-remembering-flash-thoughts-on-thoughts-on-flash-mdx" */),
  "component---src-pages-writing-test-piece-js": () => import("./../../../src/pages/writing/test-piece.js" /* webpackChunkName: "component---src-pages-writing-test-piece-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-project-detail-js": () => import("./../../../src/templates/project-detail.js" /* webpackChunkName: "component---src-templates-project-detail-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */)
}

